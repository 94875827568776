// Core

body {
  .app-sidebar-heading {
    padding: $spacer $spacer ($spacer / 1.1);
    font-weight: bold;
    color: $blue;
    text-transform: uppercase;
    font-size: $font-size-sm;
    white-space: nowrap;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  // Sidebar menu item

  .app-sidebar-item {
    display: block;
    padding-top: 0px;
    padding-bottom: 4px;
  }

  .MuiButton-startIcon {
    margin-left: 1px;
    margin-right: 8px;
  }

  .app-sidebar-button {
    padding: 12px 10px;
    justify-content: flex-start;
    text-transform: none;
    font-size: 15px;
    width: 93%;
    border-radius: 0px;
    background: #343f6c;
    box-sizing: content-box;
    font-weight: normal;
    color: #ffffff;
    border: medium none;
    // white-space: nowrap;

    &.active,
    &:hover {
      border-left: none;
      border-top: none;
      border-bottom: none;
      background:#6579c7;
      color: #ffffff;
    }
  }

  .app-sidebar-button-wrapper {
    padding: 12px 10px;
    justify-content: flex-start;
    text-transform: none;
    width:92%;
    border-radius: 0px;
    transition: $transition-base;
    

    border-right: 4px solid #a3f962;
    border-top: medium none;
    border-bottom: medium none;
    border-left: medium none;
    box-sizing: content-box;

    &.depth-0 {
      font-weight: 500;
    }

    font-size: 14px;
    color: #fff;

    &.active,
    &:hover {
      background: #55edad;
      border-right: 4px solid #55edad;
      color: #2e2e2ead;
    }

    &.active-item {
      background: #fff;
      border-right: 4px solid #fff;
      color: #2e2e2ebb;
    }
  }

  .app-sidebar-icon {
    color: theme-color($primary);
    display: flex;
    align-items: center;
    margin-right: 16px;
  }

  .sidebar-expand-icon {
    margin-left: auto;
    height: 16px;
    width: 16px;
    opacity: .6;
    transition: $transition-base;
  }

  .sidebar-expand-icon-rotate {
    opacity: .9;
    transform: rotate(180deg);
  }

  .menu-item-label {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .active-item {
    color: $primary;
    font-weight: 500;
    .app-sidebar-icon {
      color: $primary;
    }
  }
}
