body{
    overflow:auto !important;
}
.payicon.btn-wrapper--icon.inactive {
    color: gray !important;
}

.paymethods.inactive {
    border-color: gray;
}

body .app-sidebar-nav-close button span span {
    opacity: 1 !important;
    visibility: visible !important;
}

.app-sidebar-header.app-sidebar-header-close {
    width: 270px !important;
    /* background: #3d4977; */
    /* color: #fff; */
}

/* .MuiButtonBase-root.MuiIconButton-root.header-logo-wrapper-btn.MuiIconButton-colorPrimary {
    background: #3d4977;
} */

.MuiTypography-root.app-sidebar-heading.MuiTypography-body1 {
    display: none;
}